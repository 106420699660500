import Member from "../../components/OurTeam/TeamMember";

let teamPhoto = require("../../assets/TMSBrandPhotos2022-309.jpg");


let amy = require("../../assets/staffPictures/Amy.jpg");
let jazmin = require("../../assets/staffPictures/Jazmin.jpg");
let kailee = require("../../assets/staffPictures/Kailee.jpg");
let miguel = require("../../assets/staffPictures/Miguel.jpg");
let irene = require("../../assets/staffPictures/Irene.jpg");

let cheeja = require("../../assets/staffPictures/Cheeja.jpg");
let hleeda = require("../../assets/staffPictures/Hleeda.jpg");
let hnouchee = require("../../assets/staffPictures/Hnouchee.jpg");
let jaden = require("../../assets/staffPictures/Jaden.jpg");
let gissell  = require("../../assets/staffPictures/Gissell.jpg");




export default function PrivacyPolicy() {
return(
    <div>
        <div className="threeTextOneImgContainer">
            <div>
                <h4><em>#TeamTMS</em></h4>
                <h1>Meet The Team</h1>
                <p>The heart, soul & hands of the organization, Tiny Mighty & Strong's team works diligently to deliver quality, accessible programming in all service areas.  With an emphasis on teamwork & individual commitment, the Tiny Mighty & Strong team represents an impressive array of skillsets, community connection & true passion for serving.</p>
            </div>
            <div>
                <img src={teamPhoto} alt="" />
            </div>
        </div>
        <div className="d-flex align-items-center flex-column">

            <h1>Tiny Mighty & Strong Staff</h1>
            <div className="memberContainer">
                <Member
                img={amy}
                name={"Amy Frey"}
                position={"ceo/founder"}
                link={"amy"}
                />
                <Member
                img={jazmin}
                name={"Jazmin Alonso"}
                position={"ceo/founder"}
                link={"jazmin"}
                />
                <Member
                img={kailee}
                name={"Kaille Wood"}
                position={"ceo/founder"}
                />
                <Member
                img={miguel}
                name={"Miguel Navarro"}
                position={"ceo/founder"}
                link={"miguel"}
                />
                <Member
                img={irene}
                name={"Irene  Aguirre"}
                position={"ceo/founder"}
                link={"irene"}
                />
            </div>

            <h1>Support Staff</h1>

            <div className="memberContainer">
                <Member
                img={cheeja}
                name={"Cheeja Chue"}
                position={"ceo/founder"}
                />
                <Member
                img={hleeda}
                name={"Hleeda Chue"}
                position={"ceo/founder"}
                />
                <Member
                img={hnouchee}
                name={"Hnouchee Chue"}
                position={"ceo/founder"}
                />
                <Member
                img={jaden}
                name={"Jaden Navarro"}
                position={"ceo/founder"}
                />  
                <Member
                img={gissell}
                name={"Gissell Hernandez"}
                position={"ceo/founder"}
                /> 
            </div>
            
        </div>
    </div>
)
}