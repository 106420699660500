import "./App.scss";
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useContext } from "react";
import { getContext } from "../../services/ContextFactory";

import Layout from "../../components/Layout/Layout";
import HomePage from "../../views/HomePage/HomePage";
import AboutUs from "../../views/AboutUs/AboutUs";
import OurTeam from "../../views/OurTeam/OurTeam";
import Events from "../../views/Events/Events";
import EventSignup from "../../views/Events/EventSignup";
import Programs from "../../views/Programs/Programs";
import Feed from "../../views/Feed/Feed";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

import Amy from "../../views/OurTeam/Amy";
import Jazmin from "../../views/OurTeam/Jazmin";
import Kaille from "../../views/OurTeam/Kaille";
import Miguel from "../../views/OurTeam/Miguel";
import Irene from "../../views/OurTeam/Irene";


import Account from "../Account/Account";
import Child from "../Account/Child";

import NoMatch from "../../views/NoMatch/NoMatch";

let image = require("../../assets/logo.png");
let signinLink =
  "https://tinymightystrong.auth.us-west-1.amazoncognito.com/login?client_id=3lq2hl55nbkpt5ja3h4ncja6i&response_type=token&scope=email+openid+phone&redirect_uri=https://mainpage.tinymightystrong.org/";
let signoutLink =
  "https://tinymightystrong.auth.us-west-1.amazoncognito.com/logout?client_id=3lq2hl55nbkpt5ja3h4ncja6i&logout_uri=https://mainpage.tinymightystrong.org/";

function App() {
  let api = useContext(getContext());

  const [CognitoUser, setCognitoUser] = useState(null);
  const [DBUser, setDBUser] = useState(null);
  const [DBParent, setDBParent] = useState(null);
  const [DBChild, setDBChild] = useState(null);

  useEffect(() => {
    //on page load look for params in the URL
    const params = new URLSearchParams(window.location.hash.substring(1));

    let token = params.get("id_token");

    if (token) {
      //clear params from URL
      const { protocol, host, pathname } = window.location;
      const newUrl = `${protocol}//${host}${pathname}`;

      const urlWithoutToken = new URL(newUrl);
      urlWithoutToken.searchParams.delete("id_token");
      urlWithoutToken.searchParams.delete("access_token");
      urlWithoutToken.searchParams.delete("expires_in");
      urlWithoutToken.searchParams.delete("token_type");

      window.history.replaceState(
        {},
        document.title,
        urlWithoutToken.toString()
      );
    }

    //if there isn't token in params, look in local storage
    if (!token) {
      token = localStorage.getItem("auth");
    }

    //if there's an auth token in either place, store in local storage then verify
    if (token) {
      localStorage.setItem("auth", token);

      //TODO V2 get parent and child on page load if exist
      api
        .auth(token)
        .then((user) => {
          setCognitoUser(user);
          api
            .getOneParentUserUN(user["cognito:username"])
            .then((db) => {
              if (db.message === "not_found") {
                api.createParentUser({
                  AWSUsername: user["cognito:username"],
                  AWSEmail: user.email,
                  firstName: "Blank",
                  lastName: "Blank",
                });
              } else {
                setDBUser(db);
                api
                  .getOneParentUN(user["cognito:username"])
                  .then((parent) => {
                    if (parent.message === "not_found") {
                      setDBParent(null);
                    } else {
                      setDBParent(parent);
                      api
                        .getOneChildByParent(parent.parent_id)
                        .then((child) => {
                          if (child.message === "not_found") {
                            setDBChild(null);
                          } else {
                            setDBChild(child);
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          setDBChild(null);
                        });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    setDBParent(null);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
              setDBUser(null);
            });
        })

        //if there's an error verifying remove auth from state and localStorage
        .catch((error) => {
          console.log(error);
          setCognitoUser(null);
          token = null;
          localStorage.removeItem("auth");
        });
    }

    //if there isn't a token redirect to the login page
    if (!token) {
      setCognitoUser(null);
    }
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout
            title="tiny mighty strong®"
            logo={image}
            CognitoUser={CognitoUser}
            signinLink={signinLink}
            signoutLink={signoutLink}
          />
        }
      >
        <Route index element={<HomePage api={api}/>} />

        <Route
          path="/events"
          element={
            <Events
              api={api}
              DBUser={DBUser}
              DBParent={DBParent}
              DBChild={DBChild}
              signinLink={signinLink}
            />
          }
        />
        <Route
          path="/event-signup/:eventId"
          element={
            <EventSignup api={api} DBParent={DBParent} DBChild={DBChild} />
          }
        />
        <Route
          path="/programs"
          element={
            <Programs
              api={api}
              DBUser={DBUser}
              DBParent={DBParent}
              DBChild={DBChild}
              signinLink={signinLink}
            />
          }
        />
        <Route path="/feed" element={<Feed api={api} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy api={api} />} />
        <Route path="/about-us" element={<AboutUs/>} />
        <Route path="/our-team" element={<OurTeam/>} />
        <Route path="/our-team/amy" element={<Amy/>} />
        <Route path="/our-team/jazmin" element={<Jazmin/>} />
        <Route path="/our-team/kaille" element={<Kaille/>} />
        <Route path="/our-team/miguel" element={<Miguel/>} />
        <Route path="/our-team/irene" element={<Irene/>} />

        {!CognitoUser && <Route path="/account" element={<HomePage api={api}/>} />}
        {CognitoUser && (
          <>
            <Route
              path="/account/parent"
              element={
                <Account
                  CognitoUser={CognitoUser}
                  api={api}
                  DBUser={DBUser}
                  DBParent={DBParent}
                  setDBParent={setDBParent}
                />
              }
            />
            <Route
              path="/account/child"
              element={
                <Child
                  CognitoUser={CognitoUser}
                  api={api}
                  DBUser={DBUser}
                  DBParent={DBParent}
                  setDBParent={setDBParent}
                  DBChild={DBChild}
                  setDBChild={setDBChild}
                />
              }
            />
          </>
        )}

        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default App;
