import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import HeaderLogo from "./HeaderLogo";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";



export default function Header({ title, logo, CognitoUser, signinLink, signoutLink }) {
  const removeToken = () => {
    localStorage.removeItem("auth");
  };

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 50 && isVisible) {
        setIsVisible(false);
      } else if (currentScrollY === 0 && !isVisible) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  return (
    <Navbar
      variant="dark"
      bg="dark"
      expand="lg"
      className="justify-content-between sticky-top"
      collapseOnSelect
    >
      <Container>
        <div className="d-flex align-items-center">
          <Navbar.Brand href="/" className="fs-3">
            <img
              src={logo}
              alt=""
              height={50}
              className={isVisible ? 'navbar-brand my-0 py-0 inactiveLogoImage' : 'navbar-brand my-0 py-0  activeLogoImage'}            />
            {""}

          </Navbar.Brand>
        </div>
        <HeaderLogo logo={logo} isVisible={isVisible} />

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" className=" justify-content-end">
          <Nav variant="pills" defaultActiveKey="" className="fs-5">
            <NavLink
              exact
              to="/"
              className="nav-link mx-1 px-3"
              activeClassName="active"
            >
              Home
            </NavLink>

            <li className="nav-item bundled">
              <span className="nav-link main-option">About TMS</span>
              <ul className="submenu">
                <li>
                  <NavLink
                    to="about-us"
                    className="nav-link mx-1 px-3"
                    activeClassName="active"
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="our-team"
                    className="nav-link mx-1 px-3"
                    activeClassName="active"
                  >
                    Our Team
                  </NavLink>
                </li>
              </ul>
            </li>

            <NavLink
              to="events"
              className="nav-link mx-1 px-3"
              activeClassName="active"
            >
              Events
            </NavLink>
            <NavLink
              to="programs"
              className="nav-link mx-1 px-3"
              activeClassName="active"
            >
              Our Programs
            </NavLink>
            <NavLink
              to="feed"
              className="nav-link mx-1 px-3"
              activeClassName="active"
            >
              Feed
            </NavLink>
            <NavLink
              to="https://tmsyouthprograms.networkforgood.com/projects/203957-support-tiny-mighty-and-strong"
              className="px-3 mx-2 donateBtn nav-link"
              activeClassName="active"
            >
              Donate
            </NavLink>

            {/* Dissabled untill parent portal is finished  */}
            {/* {CognitoUser && (
              <NavLink
                to={signoutLink}
                className="signInBtn nav-link mx-2 px-3"
                activeClassName="active"
                onClick={removeToken}
              >
                Sign Out
              </NavLink>
            )}
            {CognitoUser && (
              <NavLink
                to="account/parent"
                className="nav-link mx-1 px-4"
                activeClassName="active"
              >
        <FontAwesomeIcon icon={faUser}/>
              </NavLink>
            )}
            {!CognitoUser && (
              <NavLink
                to={signinLink}
                className="signInBtn nav-link mx-2 px-4"
                activeClassName="active"
              >
                Sign In
              </NavLink>
            )} */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
