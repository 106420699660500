import { Link } from "react-router-dom";

export default function PageLinks() {
  return (
    <div className="p-4">
      <h3 className="pt-4" d-flex>Page Links</h3>
      <div className="d-flex align-items-center"></div>
      <Link className="d-flex" to="/">
        Home
      </Link>
      <Link className="d-flex" to="Events">
        Events
      </Link>
      <Link className="d-flex" to="Programs">
        Our Programs
      </Link>
      <Link className="d-flex" to="Donate">
        Donate
      </Link>
      <Link className="d-flex" to="SignUp">
        Sign Up
      </Link>
    </div>
  );
}
