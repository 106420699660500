import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

let amy = require("../../assets/staffPictures/Amy2.jpg");




export default function PrivacyPolicy() {

return(

    <div>
        <div className="threeTextOneImgContainer">
            <div>
                <h4><em>#TeamTMS</em></h4>
                <h1>Meet Amy</h1>
                <p>A native of Tulelake, CA, she was fortunate enough to have been raised in a community that provided safety and access for me to pursue my dreams. After 14 years of living in Southern California, she returned and was made acutely aware of the incredible access that large cities afforded her, and how improbable the same access and opportunities are to her peers in her rural hometown. This dearth of access to a quality education and healthcare for underrepresented communities compelled her to pursue an MBA; which resulted in Tiny, Mighty and Strong (TMS) 501c3. TMS will serve as the vehicle to help change opportunities and access for youth enrichment programs for all youth that reside in southern Klamath County and northern Siskiyou and Modoc Counties. As a native to the Basin, and Founder/President of TMS, she is eager to support all youth in our service communities.</p>
                <br />
                <h4><em>Contact Info</em></h4>
                <p><FontAwesomeIcon icon={faEnvelope}/> <b> Email - afrey@tinymightystrong.org</b></p>
            </div>
            <div>
                <img src={amy} alt="" />
            </div>
        </div>


    </div>


)
}