import { Link } from "react-router-dom";

function DonateModal({ image }) {
  return (
    <div className="donateImage mb-5">
      <div className="donateModal text-center">
        <h2>Support Children's Activities</h2>
        <p>
          Help us provide enriching activities for children in our community.
        </p>

        <div className="donation-benefits">
          <h3>How Your Donation Helps:</h3>
          <ul>
            <li>
              <strong>Enriching Programs:</strong> Your support allows us to
              offer diverse activities, fostering creativity and personal
              growth.
            </li>
            <li>
              <strong>Access for All:</strong> Ensure every child, regardless of
              background, can participate through scholarships and resources.
            </li>
            <li>
              <strong>Building Confidence:</strong> Create a positive
              environment where children develop confidence and important social
              skills.
            </li>
          </ul>
        </div>

        <div className="donation-options">
          <h3>Ways to Donate:</h3>
          <ul>
            <li>One-Time Donation</li>
            <li>Monthly Giving</li>
            <li>In-Kind Donations</li>
          </ul>
        </div>

        <Link
          className="btn btn-primary "
          to="https://tmsyouthprograms.networkforgood.com/projects/203957-support-tiny-mighty-and-strong"
        >
          Donate
        </Link>
      </div>
    </div>
  );
}

export default DonateModal;
