import React, { useEffect, useState } from "react";
import DonateModal from "../../components/HomePage/DonateModal";
import SlideShow from "../../components/HomePage/SlideShow";
import Stats from "../../components/HomePage/Stats";
import AboutUs from "../../components/HomePage/AboutUs";

let image = require("../../assets/TMSBrandPhotos2022-260.jpg");

let ourMissionImageOne = require("../../assets/TMSBrandPhotos2022-223.jpg");
let ourMissionImageTwo = require("../../assets/TMSBrandPhotos2022-73.jpg");

let ourVisionImage = require("../../assets/TMSBrandPhotos2022-281.jpg");




export default function HomePage({ api }) {
  const [events, setEvents] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.listAllEvents().then((items) => {
      setEvents(items);
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="w-100 h-50">
      {/* className="fade show main-area col-md-10 justify-content-center rounded shadow"
     <div className="imageOverlay">
        <img className="homepageImage" src={image} alt="Main group" />
      </div>
        <div className="imageText">
        <h1>Building Bright Futures</h1>
        </div>  */}

      <DonateModal img={image} />

      {isLoading && (
        <div className="mb-5" style={{ height: "185px", width: "100%" }}>
          {" "}
        </div>
      )}

      {!isLoading && <SlideShow image={image} events={events} />}

      <AboutUs />

      <Stats
        youthServed={18320}
        retentionRate={91}
        communitiesServed={6}
        yearsOfService={3}
      />

      <div className="homeOurMissionContainor">
        <div>
          <h4><em>Empowerment Through Education</em></h4>
          <h1>Our Mission</h1>
          <p>Tiny Mighty & Strong's mission is to <b>change the world by empowering & educating youth to live healthier lives through education.</b><br /><br />We are proud to serve rural communities in Northern California & Southern Oregon, providing affordable, accessible, quality programming that changes lives.</p>
        </div>
        <div className="">
          <img src={ourMissionImageOne} alt="" />
          <img src={ourMissionImageTwo} alt="" />
        </div>
      </div>

      <div className="homeOurVisionContainor">
        <div>
          <h4><em>Moving Forward With Purpose</em></h4>
          <h1>Our Vision</h1>
          <p>Tiny Mighty & Strong's vision is <b>to be a non-profit organization without walls that serves underrepresented communities in rural America with quality enrichment programs for youth.</b></p>
        </div>
        <div>
          <img src={ourVisionImage} alt="" />
        </div>
      </div>

    </div>
  );
}
