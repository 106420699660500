import Stats from "../../components/HomePage/Stats";

import { Link } from "react-router-dom";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faLightbulb, faTrophy, faHandHoldingHeart } from "@fortawesome/free-solid-svg-icons";

let brandPhoto = require("../../assets/TMSBrandPhoto.jpg");
let brandPhoto2 = require("../../assets/TMSBrandPhoto2.jpg");


let orgizationalPhoto = require("../../assets/TMSBrandPhotos2022-13-300x200.jpg");
let valuesPhoto = require("../../assets/TMSBrandPhotos2022-261-300x200.jpg");
let missionPhoto = require("../../assets/TMSBrandPhotos2022-286-300x200.jpg");

let impactOne = require("../../assets/TMS-Year-One-Impact.jpg");
let impactTwo = require("../../assets/TMS-Year-Two-Impact.jpg");
let impactThree = require("../../assets/TMS-Year-Three-Impact-800x1024.jpg");


export default function PrivacyPolicy() {

    return(
        <div>
            <div className="aboutUsSectionOne d-flex  mb-5 mt-5 pt-5 ">
                <div className="aboutUs" style={{flex: "1"}}>
                    <h4><em>About Us</em></h4>
                    <h1>Built To Serve</h1>
                    <p>Tiny Mighty and Strong Corporation was founded in 2019 with a desire to enrich & lift up the underserved youth in the communities of Dorris, Macdoel, Malin, Merrill, Newell and Tulelake in Northern California & Southern Oregon. As a 501(c)(3) non-profit, our organization is dedicated to serving through a robust series of youth classes, summer camp programs, community connection events, food distribution campaigns & ongoing enrichment opportunities.</p>
                </div>
                <div className="aboutUsBrandPhoto" style={{flex: "1"}}>
                    <img src={brandPhoto2} alt="" />
                    <img src={brandPhoto} alt="" />
                </div>
            </div>

            <div className="aboutUsSectionTwo d-flex ourStatmentContainer">
                <div className="d-flex flex-column">
                    <h4>Our Mission</h4>
                    <img src={missionPhoto} alt="" />
                    <p>Tiny Mighty & Strong's mission is to change the world by empowering & educating youth to live healthier lives through education. <br /><br /> <b>We are proud to serve youth and families in rural America.</b></p>
                </div>
                <div className="d-flex flex-column">
                    <h4>Our Organizational Pillars</h4>
                    <img src={orgizationalPhoto} alt="" />
                    <p>Tiny Mighty & Strong believes in providing tools & opportunities to build strong futures. <br /><br /> <b>We instill healthy habits through lessons in nutrition & cooking, fitness, leadership development and emotional & mental health.</b></p>
                </div>
                <div className="d-flex flex-column">
                    <h4>Our Core Values</h4>
                    <img src={valuesPhoto} alt="" />
                    <p>Tiny Mighty & Strong strives to remain true to its core values in every program we provide throughout our service communities: <br /><br /> <b>Above all, we promote the values of Safety, Service, Love, Courage & Empowerment.</b></p>
                </div>
            </div>
            <br />
            <Stats
        youthServed={18320}
        retentionRate={91}
        communitiesServed={6}
        yearsOfService={3}
      />

            <div className="impactContainer mb-5">
                <img className="impactReports" src={impactOne} alt="" />
                <img className="impactReports" src={impactTwo} alt="" />
                <img className="impactReports" src={impactThree} alt="" />
            </div>

            <div className="pt-4" style={{backgroundColor: "rgb(11,21,54)", color: "white"}}>
                <div className="aboutUsSectionThree d-flex flex-column align-items-center pt-5">
                    <h4 className="mb-3" style={{color: "rgb(30,115,190)"}}><em>What We Do?</em></h4>
                    <h1>Education, Empowerment & Essential Services</h1>
                    <p className="greyText mt-2">Tiny Mighty & Strong provides opportunities for the youth in our communities to increase their health, confidence & well-being through a wide variety of programs & events.</p>
                </div>
                <div className="d-flex whatWeDoContainer">
                    <div>
                        <FontAwesomeIcon icon={faHeart} size="3x" style={{color:"#145C5E"}} />
                        <h3>Youth Classes</h3>
                        <p>Each season, Tiny Mighty & Strong offers enrichment classes for local youth ages 2-18. These classes cover a wide range of areas which build skills, encourage creativity, promote healthy eating & movement and provide an immersive experience in the arts. Class offerings rotate every season to meet the needs of our community, with options for music, dance, sports,  nutrition, creative expression & more.</p>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faLightbulb} size="3x" style={{color:"rgb(31,115,191)"}} />
                        <h3>Summer Camps</h3>
                        <p>To help encourage continuous learning & health, Tiny Mighty & Strong offers a summer camp series for local youth. With a structured curriculum offering lessons in sports, nutrition, outdoor exploration, community building & Science, Technology, Engineering & Math (STEM), campers have the opportunity to develop skills which build the foundation of life-long health.</p>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faTrophy} size="3x" style={{color:"rgb(33,215,217)"}} />
                        <h3>Youth Leadership</h3>
                        <p>Each summer, Tiny Mighty & Strong offers an immersive leadership experience for local youth in junior high and high school. Participants learn how to harness their unique talents, passions & vision while building leadership skills. Our program teaches youth what it means to be confident, compassionate leaders while growing personally, professionally & academically.</p>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faHandHoldingHeart} size="3x" style={{color:"rgb(247,169,0)"}} />
                        <h3>Community Building</h3>
                        <p>Tiny Mighty & Strong believes in supporting connection, joy & health through the sponsorship of community holiday events, school events & cultural enrichment events. In partnership with local food hubs & distribution organizations, Tiny Mighty & Strong offers opportunities for families to access healthy food through food box, produce pick-up & other nutrition programs.</p>
                    </div>
                </div>
            </div>

            <div className="aboutUsDonate">
                <h4><em>Make A Difference</em></h4>
                <h1>Help Support Our Growth</h1>
                <p>Tiny Mighty & Strong is on a mission to transform lives & communities in the areas we serve. If you would like to support our team financially, in-kind or with the donation of your time, please reach out anytime.</p>
                <Link
                    className="aboutUsDonateBtn "
                    to="https://tmsyouthprograms.networkforgood.com/projects/203957-support-tiny-mighty-and-strong"
                >
                    Donate Today
                </Link>
            </div>
        </div>
        
    )
}