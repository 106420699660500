<iframe
  width="560"
  height="315"
  src="https://www.youtube.com/embed/N8iAhTtjC4I?si=rorJInH8BQlNPCVq"
  title="YouTube video player"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  allowfullscreen
></iframe>;

export default function Stats() {
  let videoURL =
    "https://www.youtube.com/embed/N8iAhTtjC4I?si=rorJInH8BQlNPCVq";
  return (
    <div className="threeTextOneImgContainer">
      <div>
        <h4><em>About Us</em></h4>
        <h1>Together We Are Mighty</h1>
        <p>Tiny Mighty and Strong Corporation was founded in 2019 with a desire to enrich & lift up the underserved youth in the communities of Dorris, Macdoel, Malin, Merrill, Newell and Tulelake in Northern California & Southern Oregon. As a 501(c)(3) non-profit, our organization is dedicated to serving through a robust series of youth classes, summer camp programs, community connection events, food distribution campaigns & ongoing enrichment opportunities.</p>
      </div>
      <div className="aboutVideo">
        <iframe
          className="w-100 h-100"
          src={videoURL}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}
