import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import AccSubMenu from "../../components/Account/AccSubMenu"
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export default function Child({ api, DBParent, DBChild, setDBChild }) {
  const navigate = useNavigate();

  const [childDetails, setChildDetails] = useState({
    firstName: "",
    lastName: "",
    email: "", // should be optional but isn't in db
    cellPhone: "", // optional
    dateOfBirth: "",
    bvschoolDist: false,
    schoolName: "",
    grade: "",
    gender: "",
    race: 0,
    ethnicity: 0,

    childrenLiveWith: 0,
    healthProvider: 0,
    medicalProblems: "", // optional
    allergies: "", // optional
    diet: "", // optional
    disabilities: "", // optional
    medication: "", // optional
    epipen: false,
    asthma: false,
    otherAssistance: "", // optional

    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    state: "",
    zipcode: "",

    isSameAddressParentStreet: false,
    // parentID:
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  // const [DBChild, setDBChild] = useState(null);

  const handleSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 8000);
  };

  const handleFailure = () => {
    setShowFailure(true);
    setTimeout(() => setShowFailure(false), 8000);
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    if (e.target.type == "checkbox") {
      value = e.target.checked;
    }
    setChildDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear validation error once user starts typing in the field
    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    validateInput(name);
  };

  const validateInput = (name) => {
    let error = "";
    const value = childDetails[name];

    if (
      // name !== "email" &&
      name !== "cellPhone" &&
      name !== "medicalProblems" &&
      name !== "allergies" &&
      name !== "diet" &&
      name !== "disabilities" &&
      name !== "medication" &&
      name !== "otherAssistance"
    ) {
      if (!value) {
        error = "This field is required";
      } else if (name === "email" && !isValidEmail(value)) {
        error = "Invalid email format";
      } else if (name === "race" && value === 0) {
        error = "This field is required";
      } else if (name === "ethnicity" && value === 0) {
        error = "This field is required";
      }
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  useEffect(() => {
    setIsSaveDisabled(hasErrors());
  }, [validationErrors]);

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const hasErrors = () => {
    return Object.values(validationErrors).some((error) => error);
  };

  const handleSave = () => {
    if (!hasErrors()) {
      if (DBParent) {
        //if the parent is in the state
        if (DBChild) {
          //if child in state, update child
          api
            .updateChild(DBChild.child_id, childDetails)
            .then(() => {
              handleSuccess();
            })
            .catch((e) => {
              console.log(e);
              handleFailure();
            });
          setIsSaveDisabled(true); // Disable save button after successful save
        } else {
          //if not create child and store in state
          api
            .createChild(childDetails)
            .then((res) => {
              api.getOneChildByParent(res.parentID).then((res) => {
                setDBChild(res);
                handleSuccess();
              });
            })
            .catch((e) => {
              console.log(e);
              handleFailure();
            });

          setIsSaveDisabled(true); // Disable save button after successful save
        }
      } else {
        //redirect if parent
        navigate("/account/parent");
      }
      // }
    }
  };

  useEffect(() => {
    //on page load check if child database object is already loaded into state
    if (!DBChild) {
      //if not make sure parent is loaded into state, and get child by parent
      if (DBParent) {
        api
          .getOneChildByParent(DBParent.parent_id)
          .then((dbChild) => {
            if (dbChild.message == "not_found") {
              //if child doesn't exists in db set state to null
              setDBChild(null);
            } else {
              //if child already in db exists set state to child
              setDBChild(dbChild);
              let formatedDate = dayjs(dbChild.dateOfBirth).format(
                "YYYY-MM-DD"
              );
              setChildDetails({
                ...dbChild,
                dateOfBirth: formatedDate,
                isSameAddressParentStreet: false,
                epipen: false,
                asthma: false,
                bvschoolDist: false,
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });

        //add parent ID to child state
        setChildDetails((prevState) => ({
          ...prevState,
          parentID: DBParent.parent_id,
        }));

        setChildDetails((prevState) => ({
          ...prevState,
          childrenLiveWith: DBParent.parent_id,
        }));
      } else {
        //redirect to parent
        navigate("/account/parent");
      }
    } else {
      //if child is loaded into state set form data to info
      // setDisableInputs(true);
      let formatedDate = dayjs(DBChild.dateOfBirth).format("YYYY-MM-DD");
      setChildDetails({
        ...DBChild,
        dateOfBirth: formatedDate,
        isSameAddressParentStreet: false,
        epipen: false,
        asthma: false,
        bvschoolDist: false,
      });
    }
  }, []);

  return (
    <div className="pt-5 mx-4">
      <AccSubMenu />

      <div className="account-container mb-4">
        <h2 className="mb-4">Child Information</h2>

        <div className="input-group">
          <label>First Name:*</label>
          <input
            type="text"
            name="firstName"
            value={childDetails.firstName}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.firstName && (
            <span className="error">{validationErrors.firstName}</span>
          )}
        </div>
        <div className="input-group">
          <label>Last Name:*</label>
          <input
            type="text"
            name="lastName"
            value={childDetails.lastName}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.lastName && (
            <span className="error">{validationErrors.lastName}</span>
          )}
        </div>

        <div className="input-group">
          <label>Email:*</label>
          <input
            type="email"
            name="email"
            value={childDetails.email}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.email && (
            <span className="error">{validationErrors.email}</span>
          )}
        </div>

        <div className="input-group">
          <label>Cell Phone: </label>
          <input
            type="tel"
            name="cellPhone"
            value={childDetails.cellPhone}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.cellPhone && (
            <span className="error">{validationErrors.cellPhone}</span>
          )}
        </div>

        <div className="input-group">
          <label>Date of Birth:*</label>
          <input
            type="date"
            name="dateOfBirth"
            value={childDetails.dateOfBirth}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.dateOfBirth && (
            <span className="error">{validationErrors.dateOfBirth}</span>
          )}
        </div>
      </div>

      <div className="account-container mb-4">
        <h2 className="mb-4">School Information</h2>

        <div className="input-group mb-5 mt-4">
          <label className="checkboxLabel">
            <input
              type="checkbox"
              name="bvschoolDist"
              checked={childDetails.bvschoolDist}
              onChange={handleInputChange}
            />
            Is your Child currently or will your Child be enrolled in Butte
            Valley Unifed School District?
          </label>
        </div>

        <div className="input-group">
          <label>School Name:*</label>
          <input
            type="text"
            name="schoolName"
            value={childDetails.schoolName}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.schoolName && (
            <span className="error">{validationErrors.schoolName}</span>
          )}
        </div>
        <div className="input-group">
          <label>Grade:*</label>
          <input
            type="text"
            name="grade"
            value={childDetails.grade}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.grade && (
            <span className="error">{validationErrors.grade}</span>
          )}
        </div>
      </div>

      <div className="account-container mb-4">
        <h2 className="mb-4">Other</h2>

        <div className="input-group">
          <label>Gender:*</label>
          <input
            type="text"
            name="gender"
            value={childDetails.gender}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.gender && (
            <span className="error">{validationErrors.gender}</span>
          )}
        </div>
        <div className="input-group">
          <label>Race:*</label>
          <select
            name="race"
            value={childDetails.race}
            onChange={handleInputChange}
            onBlur={handleBlur}
          >
            <option value={0}>Select Option:</option>
            <option value={1}>White</option>
            <option value={2}>Black or African American</option>
            <option value={3}>Asian</option>
            <option value={4}>Native American or Alaska Native</option>
            <option value={5}>Native Hawaiian or Other Pacific Islander</option>
            <option value={6}>Other</option>
          </select>
          {validationErrors.race && (
            <span className="error">{validationErrors.race}</span>
          )}
        </div>

        <div className="input-group">
          <label>Ethnicity:*</label>
          <select
            name="ethnicity"
            value={childDetails.ethnicity}
            onChange={handleInputChange}
            onBlur={handleBlur}
          >
            <option value={0}>Select Option:</option>
            <option value={1}>Hispanic or Latino</option>
            <option value={2}>Not Hispanic or Latino</option>
            <option value={3}>Prefer not to say</option>
            <option value={4}>Other</option>
          </select>
          {validationErrors.ethnicity && (
            <span className="error">{validationErrors.ethnicity}</span>
          )}
        </div>
      </div>

      <div className="account-container mb-4">
        <h2 className="mb-4">Address</h2>

        <div className="input-group mb-4 mt-4">
          <label className="checkboxLabel">
            <input
              type="checkbox"
              name="isSameAddressParentStreet"
              checked={childDetails.isSameAddressParentStreet}
              onChange={handleInputChange}
            />
            Child lives at the same address as the Parent
          </label>
        </div>

        {!childDetails.isSameAddressParentStreet && (
          <>
            <div className="input-group">
              <label>Street Address:*</label>
              <input
                type="text"
                name="streetAddressLine1"
                value={childDetails.streetAddressLine1}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {validationErrors.streetAddressLine1 && (
                <span className="error">
                  {validationErrors.streetAddressLine1}
                </span>
              )}
            </div>
            <div className="input-group">
              <label>City:*</label>
              <input
                type="text"
                name="city"
                value={childDetails.city}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {validationErrors.city && (
                <span className="error">{validationErrors.city}</span>
              )}
            </div>
            <div className="input-group">
              <label>State:*</label>
              <input
                type="text"
                name="state"
                value={childDetails.state}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {validationErrors.state && (
                <span className="error">{validationErrors.state}</span>
              )}
            </div>
            <div className="input-group">
              <label>Zipcode:*</label>
              <input
                type="text"
                name="zipcode"
                value={childDetails.zipcode}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {validationErrors.zipcode && (
                <span className="error">{validationErrors.zipcode}</span>
              )}
            </div>{" "}
          </>
        )}
      </div>

      <div className="account-container mb-4">
        <h2 className="mb-4">Medical</h2>
        <div className="input-group">
          <label>Health Provider:*</label>
          <select
            name="healthProvider"
            value={childDetails.healthProvider}
            onChange={handleInputChange}
            onBlur={handleBlur}
          >
            <option value={0}>Select Option:</option>
            <option value={1}>ABC Health Clinic</option>
            <option value={2}>XYZ Medical Center</option>
            <option value={3}>City Hospital</option>
            <option value={4}>Community Care Clinic</option>
            <option value={5}>Sunrise Health Services</option>
            <option value={6}>Hope Medical Group</option>
            <option value={7}>Golden Gate Medical Center</option>
            <option value={8}>Valley View Hospital</option>
            <option value={9}>Unity Healthcare</option>
            <option value={10}>Pine Grove Family Clinic</option>
          </select>
          {validationErrors.healthProvider && (
            <span className="error">{validationErrors.healthProvider}</span>
          )}
        </div>
        <div className="input-group">
          <label>List any medical problems: </label>
          <input
            type="text"
            name="medicalProblems"
            value={childDetails.medicalProblems}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.medicalProblems && (
            <span className="error">{validationErrors.medicalProblems}</span>
          )}
        </div>
        <div className="input-group mb-3 mt-4">
          <label className="checkboxLabel">
            <input
              type="checkbox"
              name="epipen"
              checked={childDetails.epipen}
              onChange={handleInputChange}
            />
            Does your child have an epipen?
          </label>
        </div>
        <div className="input-group mb-4 mt-4">
          <label className="checkboxLabel">
            <input
              type="checkbox"
              name="asthma"
              checked={childDetails.asthma}
              onChange={handleInputChange}
            />
            Does your child have asthma?
          </label>
        </div>
        <div className="input-group">
          <label>List any allergies: </label>
          <input
            type="text"
            name="allergies"
            value={childDetails.allergies}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.allergies && (
            <span className="error">{validationErrors.allergies}</span>
          )}
        </div>
        <div className="input-group">
          <label>List any dietary restrictions: </label>
          <input
            type="text"
            name="diet"
            value={childDetails.diet}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.diet && (
            <span className="error">{validationErrors.diet}</span>
          )}
        </div>

        <div className="input-group">
          <label>List any disabilities:</label>
          <input
            type="text"
            name="disabilities"
            value={childDetails.disabilities}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.disabilities && (
            <span className="error">{validationErrors.disabilities}</span>
          )}
        </div>

        <div className="input-group">
          <label>List any medication:</label>
          <input
            type="text"
            name="medication"
            value={childDetails.medication}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.medication && (
            <span className="error">{validationErrors.medication}</span>
          )}
        </div>

        <div className="input-group">
          <label>List any other assistance:</label>
          <input
            type="text"
            name="otherAssistance"
            value={childDetails.otherAssistance}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.otherAssistance && (
            <span className="error">{validationErrors.otherAssistance}</span>
          )}
        </div>
      </div>

      <div className="account-container mb-4">
        <button
          className="btn btn-primary w-100"
          onClick={handleSave}
          disabled={isSaveDisabled}
        >
          Save
        </button>

        <Alert show={showSuccess} variant="success">
          You've successfully added {childDetails.firstName} {childDetails.lastName} as a child.
        </Alert>
        <Alert show={showFailure} variant="danger">
          Something went wrong, try again.
        </Alert>
      </div>
    </div>
  );
}
