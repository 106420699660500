import React from "react";
import { Link } from "react-router-dom";

import PageLinks from "./PageLinks";
import SocialMedia from "./SocialMedia";
import Partners from "./Partners";

export default function Footer() {
  return (
    <div className="p-2 footer">
      <div className="d-flex w-100 justify-content-center m-2 p-2 footerLinks">
        <PageLinks />
        
        <SocialMedia />

        <Partners />

      </div>
      
      <p> © 2022 Tiny Mighty and Strong Corporation. All rights reserved.</p>

      <Link target="_blank" className="p-2" to="/privacy-policy">
        <p> Privacy Policy </p>
      </Link>
    </div>
  );
}
