import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

let jazmin = require("../../assets/staffPictures/Jazmin2.jpg");




export default function PrivacyPolicy() {

return(

    <div>
        <div className="threeTextOneImgContainer">
            <div>
                <h4><em>#TeamTMS</em></h4>
                <h1>Meet Jazmin</h1>
                <p>Meet Jazmin: Also a native of Tulelake, she graduated from Tulelake High School in 2012 and went on to receive her Bachelors in Community Health Science from the University of Nevada, Reno. She returned to the Basin in May 2019 when Amy brought her in as a Program Manager to help with Tiny, Mighty and Strong (TMS). Since she was a little girl she knew that she wanted to help people but didn’t know what that looked like. Being able to give back to the community that raised her has been an amazing adventure and has allowed her to grow so much on a personal and professional level.<br></br><br></br> 

In her downtime, Jazmin spends her time reading, or baking new recipes. She loves to travel and explore new places. She loves airports because they connect us to the rest of the world. Her favorite place in the world is the beach.</p>
                <br />
                <h4><em>Contact Info</em></h4>
                <p><FontAwesomeIcon icon={faEnvelope}/> <b> Email - jalonso@tinymightystrong.org</b></p>
            </div>
            <div>
                <img src={jazmin} alt="" />
            </div>
        </div>


    </div>


)
}