export default function HeaderLogo({ logo, isVisible }) {


  return (
    <div
      className="logo"
      style={{
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(-10%)",
        transition: "opacity 0.5s ease, transform 0.5s ease",
      }}
    >
      <div className="logoBox">
        <img src={logo} alt="" height={50} className="navLogo" />
        {""}
        <svg
          className="logoBottom"
          width="230"
          height="42"
          viewBox="0 0 230 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M204.757 17.8278C223.99 15.3003 229.599 4.92728 230 0.0566846L0.405189 0.0565953C-0.396031 0.451498 0.324757 -3.6555 0.00420545 19.4074C-0.316346 42.4704 17.7681 43.4972 26.8504 41.1277C78.1387 34.4142 185.523 20.3552 204.757 17.8278Z"
            fill="#FFF333"
          />
        </svg>
      </div>
    </div>
  );
}
