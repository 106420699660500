class API_CUST {
  "use strict"
  constructor() {
    //General variables
    let port = ""//":8080"
    let urlCurrent = "https://dev.tinymightystrong.org"
    //General methods not accessable outside class
    const apiUrl = () => `${urlCurrent}${port}`
    const doQuery = async (url, options) => {
      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if(!response.ok) throw new Error("Network Error")
        return data;
      } catch (err) { throw err;}
    }
    const list = async (type) => {
      let url=`${apiUrl()}/${type}/`
      return doQuery(url, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    })}
    const create = async (type, obj) => {
      let url=`${apiUrl()}/${type}/`
      return doQuery(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(obj)
    })}
    const read = async (type, id) => {
      let url= `${apiUrl()}/${type}/${id}`
      return doQuery(url, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    })}
    const update = async (type, id, obj) => {
      let url= `${apiUrl()}/${type}/${id}`
      return doQuery(url, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(obj)
    })}
    const remove = async (type, id) => {
      let url= `${apiUrl()}/${type}/${id}`
      return doQuery(url, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'}
    })}

    //Classes accessable outside class
    //Parent (CRUDL)
    this.createParent = async (obj) => await create('parent', obj)
    this.getOneParent = async (id) => await read('parent', id)
    this.getOneParentUN = async (UN) => await read('parent/UN', UN)
    this.updateParent = async (id, obj) => await update('parent', id, obj)
    this.listAllParents = async () => await list('parent')

    //Child (CRUDL)
    this.getOneChild = async (id) => await read('child', id)
    this.getOneChildByParent = async (id) => await read('child/parent', id)
    this.createChild = async (obj) => await create('child', obj)
    this.updateChild = async (id, obj) => await update('child', id, obj)
    this.listAllChildren = async () => await list('child')

    //Post (CRUDL)
    this.getOnePost = async (id) => await read('post', id)
    this.listAllPosts = async () => await list('post')

    //Event (CRUDL)
    this.getOneEvent = async (id) => await read('event', id)
    this.listAllEvents = async () => await list('event')

    //Attendees (CRDL, Lspecific)
    this.createAttendee = async (obj) => await create('attendee', obj)
    this.getOneAttendee = async (id) => await read('attendee', id)
    this.deleteAttendee = async (id) => await remove('attendee', id)

    //Option (LWorkStatus, LRace, LHowYouHeard, LHealthProvider, LEthnicity)
    this.listWorkStatus = async () => await list('option/workstatus')
    this.listRace = async () => await list('option/race')
    this.listHowYouHeard = async () => await list('option/howyouheard')
    this.listHealthProvider = async () => await list('option/healthprovider')
    this.listEthnicity = async () => await list('option/ethnicity')


    //ParentUser (CRUDL)
    this.createParentUser  = async (obj) => await create('parentUser', obj)

    this.getOneParentUser  = async (id) => await read('parentUser', id)
    this.getOneParentUserUN = async (UN) => await read('parentUser/UN', UN)
    this.updateParentUser  = async (id, obj) => await update('parentUser', id, obj)
    this.deleteParentUser  = async (id) => await remove('parentUser', id)

    //Approval (CRUDL)
    this.createApproval  = async (obj) => await create('approval', obj)
    this.getOneApproval  = async (id) => await read('approval', id)
    this.updateApproval  = async (id, obj) => await update('approval', id, obj)
    this.deleteApproval  = async (id) => await remove('approval', id)

    //Cognito auth validation (read)
    this.auth = async (id) => await read('cust-auth', id)

  }


  //OBJECT Creaters
  //Parent object creater
  /**
   * @param {string} username @param {string} firstName @param {string} lastName
   * @param {string} email @param {dateAndTime} dateOfBirth @param {string} countryResidence
   * @param {string} cellPhone @param {string} homePhone @param {int} workStatus
   * @param {int} race @param {int} ethnicity @param {string} streetAddressLine1
   * @param {string} streetAddressLine2 @param {string} city @param {string} state
   * @param {string} zipcode @param {string} mailing_streetAddressLine1
   * @param {string} mailing_streetAddressLine2 @param {string} mailing_city 
   * @param {string} mailing_state @param {string} mailing_zipcode
   * @param {string} emergencyContact1_firstName @param {string} emergencyContact1_lastName 
   * @param {string} emergencyContact1_phone
   * @param {string} emergencyContact2_firstName @param {string} emergencyContact2_lastName 
   * @param {string} emergencyContact2_phone @param {bool} isSameAddress
   */
  Parent (username, firstName, lastName, 
          email, dateOfBirth, countryResidence, 
          cellPhone, homePhone, workStatus, 
          race, ethnicity, streetAddressLine1,streetAddressLine2,
          city,state,zipcode,mailing_streetAddressLine1,
          mailing_streetAddressLine2,mailing_city, mailing_state,
          mailing_zipcode,emergencyContact1_firstName,emergencyContact1_lastName, 
          emergencyContact1_phone,
          emergencyContact2_firstName,emergencyContact2_lastName, 
          emergencyContact2_phone, isSameAddress) {
    class Parent {
    constructor (username, firstName, lastName, 
            email, dateOfBirth, countryResidence, 
            cellPhone, homePhone, workStatus, 
            race, ethnicity, streetAddressLine1,streetAddressLine2,
            city,state,zipcode,mailing_streetAddressLine1,
            mailing_streetAddressLine2,mailing_city, mailing_state,
            mailing_zipcode,emergencyContact1_firstName,emergencyContact1_lastName, 
            emergencyContact1_phone,
            emergencyContact2_firstName,emergencyContact2_lastName, 
            emergencyContact2_phone, isSameAddress){
        this.username = username;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.dateOfBirth = dateOfBirth;
        this.countryResidence = countryResidence;
        this.cellPhone = cellPhone;
        this.homePhone = homePhone;
        this.workStatus = workStatus;
        this.race = race;
        this.ethnicity = ethnicity;
        this.streetAddressLine1 = streetAddressLine1
        this.streetAddressLine2 = streetAddressLine2
        this.city = city
        this.state = state
        this.zipcode = zipcode
        this.mailing_streetAddressLine1 = mailing_streetAddressLine1
        this.mailing_streetAddressLine2 = mailing_streetAddressLine2
        this.mailing_city =  mailing_city
        this.mailing_state = mailing_state
        this.mailing_zipcode = mailing_zipcode
        this.emergencyContact1_firstName = emergencyContact1_firstName
        this.emergencyContact1_lastName =  emergencyContact1_lastName
        this.emergencyContact1_phone = emergencyContact1_phone
        this.emergencyContact2_firstName = emergencyContact2_firstName
        this.emergencyContact2_lastName =  emergencyContact2_lastName
        this.emergencyContact2_phone = emergencyContact2_phone
        this.isSameAddress = isSameAddress
    }}
    return new Parent(username, firstName, lastName, 
      email, dateOfBirth, countryResidence, 
      cellPhone, homePhone, workStatus, 
      race, ethnicity, streetAddressLine1,streetAddressLine2,
      city,state,zipcode,mailing_streetAddressLine1,
      mailing_streetAddressLine2,mailing_city, mailing_state,
      mailing_zipcode,emergencyContact1_firstName,emergencyContact1_lastName, 
      emergencyContact1_phone,
      emergencyContact2_firstName,emergencyContact2_lastName, 
      emergencyContact2_phone, isSameAddress)
  }

  //Child object creater
  /**
   * @param {string} firstName @param {string} lastName @param {int} parentID
   * @param {string} email @param {dateAndTime} dateOfBirth @param {string} streetAddressLine1
   * @param {string} streetAddressLine2 @param {string} city @param {string} state
   * @param {string} zipcode @param {string} grade @param {string} gender
   * @param {int} race @param {int} ethnicity @param {bool} bvschoolDist
   * @param {string} schoolName @param {int} childrenLiveWith
   * @param {int} healthProvider @param {string} cellPhone
   * @param {string} medicalProblems @param {string} allergies @param {string} diet
   * @param {string} disabilities @param {string} medication @param {bool} epipen
   * @param {bool} asthma @param {string} otherAssistance
   * @param {bool} isSameAddressParentStreet
   * @returns {Child}
   */
  Child(firstName, lastName, parentID, email, dateOfBirth, 
      streetAddressLine1, streetAddressLine2, city, state,
      zipcode, grade, gender, race, ethnicity, 
      bvschoolDist, schoolName, childrenLiveWith, 
      healthProvider, cellPhone, medicalProblems, allergies,
      diet, disabilities, medication, epipen, asthma,
      otherAssistance, isSameAddressParentStreet) {
    class Child {
      constructor(firstName, lastName, parentID, email, 
          dateOfBirth, streetAddressLine1, streetAddressLine2,
          city, state, zipcode, grade, gender, race, ethnicity,
          bvschoolDist, schoolName, childrenLiveWith, healthProvider,
          cellPhone, medicalProblems, allergies, diet, disabilities,
          medication, epipen, asthma, otherAssistance,
          isSameAddressParentStreet) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.parentID = parentID;
        this.email = email;
        this.dateOfBirth = dateOfBirth;
        this.streetAddressLine1 = streetAddressLine1;
        this.streetAddressLine2 = streetAddressLine2;
        this.city = city;
        this.state = state;
        this.zipcode = zipcode;
        this.grade = grade;
        this.gender = gender;
        this.race = race;
        this.ethnicity = ethnicity;
        this.bvschoolDist = bvschoolDist;
        this.schoolName = schoolName;
        this.childrenLiveWith = childrenLiveWith;
        this.healthProvider = healthProvider;
        this.cellPhone = cellPhone;
        this.medicalProblems = medicalProblems;
        this.allergies = allergies;
        this.diet = diet;
        this.disabilities = disabilities;
        this.medication = medication;
        this.epipen = epipen;
        this.asthma = asthma;
        this.otherAssistance = otherAssistance;
        this.isSameAddressParentStreet = isSameAddressParentStreet;
      }
    }

    return new Child(firstName, lastName, parentID, email,
        dateOfBirth, streetAddressLine1, streetAddressLine2, 
        city, state, zipcode, grade, gender, race, ethnicity, 
        bvschoolDist, schoolName, childrenLiveWith, 
        healthProvider, cellPhone, medicalProblems, allergies,
        diet, disabilities, medication, epipen, asthma,
        otherAssistance, isSameAddressParentStreet);
  }

  //User object creater
  /**
   * @param {string} AWSUsername @param {string} AWSEmail
   * @param {string} firstName @param {string} lastName
   */
  User(AWSUsername, AWSEmail, firstName, lastName) {
    class User {
      constructor(AWSUsername, AWSEmail, firstName, lastName) {
        this.AWSUsername = AWSUsername;
        this.AWSEmail = AWSEmail;
        this.firstName = firstName;
        this.lastName = lastName;
      }
    }
    return new User(AWSUsername, AWSEmail, firstName, lastName);
  }

  //Approval object creater
  /**
   * @param {int} eventId @param {int} childId
   * @param {float} amount @param {string} description
   * @param {int} approved @param {string} staffDescription
   */
  Approval(eventId, childId, amount, description, approved, staffDescription) {
    class Approval {
      constructor(eventId, childId, amount, description, approved, staffDescription) {
        this.eventId = eventId;
        this.childId = childId;
        this.amount = amount;
        this.description = description;
        this.approved = approved;
        this.staffDescription = staffDescription;
      }
    }
    return new Approval(eventId, childId, amount, description, approved, staffDescription);
  }
}
export default API_CUST 

