import { NavLink } from "react-router-dom";


export default function Account () {

return (
<div className="account-submenu pt-4">
<NavLink
  className="nav-link sub-nav mx-1 px-3"
  activeClassName="active"
  to={`/account/parent`}
>
  Parent
</NavLink>
<NavLink
  className="nav-link sub-nav mx-1 px-3"
  activeClassName="active"
  to={`/account/child`}
>
  Child
</NavLink>
</div>

)}