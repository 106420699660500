import React from 'react'
import Header from '../../components/Header/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'


export default function Layout({ title, logo, CognitoUser, signinLink, signoutLink}) {
  return (
    <div>
        <Header title={title} logo={logo} CognitoUser={CognitoUser} signinLink={signinLink} signoutLink={signoutLink}/>
        <div className="mx-0 w-100 content-section ">
          {/* <div className="row my-2 my-sm-4 mx-1 mx-sm-2 mx-md-0 justify-content-evenly w-100"> */}
            <Outlet/>
            
          {/* </div> */}
        </div>
        <Footer/>
    </div>
  )
}
