import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";



export default function Member({
    img,
    name,
    position,
    link,
  }) {

    return (
        <div className="teamMember">
            <Link to={link}>
            <img src={img} alt="" />
            <div className="memberInfo">
                <h3 style={{color: "black"}}>{name}</h3>
                <p>{position}</p>
                <FontAwesomeIcon icon={faUser} className="icon" style={{color: "rgb(141,141,141)"}}/>
            </div>
            </Link>
        </div>
    )
  }
