import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
let LLLL = "dddd, MMMM D, YYYY h:mm A";


export default function SlideShow({ image, events }) {
 
  return (
    <div className="mb-5">
      {events && (
        <Slide autoplay="true">
          {events
            .sort((a, b) => {
              const dateA = new Date(a.dateAndTime);
              const dateB = new Date(b.dateAndTime);
              return dateA - dateB;
            })
            .filter((event) => event.type === "Event")
            .filter((event) => dayjs(event.dateAndTime).isAfter(dayjs()))
            .filter((_, index) => index < 3)
            .map((event) => (
              <div className="event each-slide-effect">
                <Link to="/events">
                  <div className="w-100 d-flex justify-content-center">
                    <div className="w-100">
                  <div className="w-100 d-flex justify-content-center">
                    <span>{event.name}</span>
                  </div>
                  <div className="eventInfo bg-light d-flex justify-content-center">

                    <div className="bg-light border slideshowBackground">
                    <div className="pt-2 px-2">
                      {dayjs(event.dateAndTime).format(LLLL)}
                    </div>
                    <div className="px-2">{event.description}</div>
                  </div>
                  </div>
                  </div>
                  </div>
                </Link>
              </div>
              
            ))}
        </Slide>
      )}
    </div>
  );
}
