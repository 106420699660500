import { Link } from "react-router-dom";

export default function Partners() {
  return (
    <div className="p-4">
      <h3 className="pt-4 d-flex">Our Partners</h3>
      <div className=""></div>
      <Link className="d-flex" to="https://www.tfff.org/">
        The Ford Family Foundation
      </Link>
      <Link className="d-flex" to="https://oregoncf.org/">
        Oregon Community Foundation
      </Link>
      <Link className="d-flex" to="https://www.bvalusd.org/">
        Butte Valley Unified School District
      </Link>
      <Link className="d-flex" to="https://elevateyouthca.org/">
        Elevate Youth California
      </Link>
    </div>
  );
}