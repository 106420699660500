import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

let miguel = require("../../assets/staffPictures/Miguel2.jpg");




export default function PrivacyPolicy() {

return(

    <div>
        <div className="threeTextOneImgContainer">
            <div>
                <h4><em>#TeamTMS</em></h4>
                <h1>Meet Miguel</h1>
                <p>Miguel graduated from Tulelake High School in 2010. He moved back to Tulelake in June 2021 and is serving his community through Tiny, Mighty and Strong and coaching the Tulelake High School Varsity Soccer team (2023 North Section Champions!). What he strives for in life is to educate the youth and share the following message: “You spend most of your life inside your head–so make it a nice place to be.” 

Miguel likes the smell of tortas, the taste of tajin con mangos, the touch of sand, and the sound of bandas.</p>
                <br />
                <h4><em>Contact Info</em></h4>
                <p><FontAwesomeIcon icon={faEnvelope}/> <b> Email - mnavarro@tinymightystrong.org</b></p>
            </div>
            <div>
                <img src={miguel} alt="" />
            </div>
        </div>


    </div>


)
}