import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import AccSubMenu from "../../components/Account/AccSubMenu"
import dayjs from "dayjs";

export default function Account({
  api,
  CognitoUser,
  DBUser,
  DBParent,
  setDBParent,
}) {
  const [accountDetails, setAccountDetails] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    streetAddressLine1Line1: "",
    streetAddressLine2: "",
    city: "",
    state: "",
    zipcode: "",
    mailing_streetAddressLine1: "",
    mailing_streetAddressLine2: "",
    mailing_city: "",
    mailing_state: "",
    mailing_zipcode: "",
    countryResidence: "",
    cellPhone: "",
    homePhone: "",
    workStatus: 0,
    race: 0,
    ethnicity: 0,
    emergencyContact1_firstName: "",
    emergencyContact1_lastName: "",
    emergencyContact1_phone: "",
    emergencyContact2_firstName: "",
    emergencyContact2_lastName: "",
    emergencyContact2_phone: "",
    isSameAddress: false,
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [disableInputs, setDisableInputs] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const handleSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 8000);
  };

  const handleFailure = () => {
    setShowFailure(true);
    setTimeout(() => setShowFailure(false), 8000);
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    setAccountDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear validation error once user starts typing in the field
    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    validateInput(name);
  };

  const validateInput = (name) => {
    let error = "";
    const value = accountDetails[name];

    if (!value) {
      error = "This field is required";
    } else if (name === "email" && !isValidEmail(value)) {
      error = "Invalid email format";
    } else if (name === "workStatus" && value === 0) {
      error = "This field is required";
    } else if (name === "race" && value == 0) {
      error = "This field is required";
    } else if (name === "ethnicity" && value === 0) {
      error = "This field is required";
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  useEffect(() => {
    setIsSaveDisabled(hasErrors());
  }, [validationErrors]);

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const hasErrors = () => {
    return Object.values(validationErrors).some((error) => error);
  };

  const handleSave = () => {
    if (!hasErrors()) {
      if (!DBParent) {
        api
          .createParent(accountDetails)
          .then((res) => {
            api.getOneParent(res.id)
            .then((res) => {
              setDBParent(res);
              handleSuccess();
            });
          })
          .catch((e) => {
            console.log(e);
            handleFailure();
          });

        setIsSaveDisabled(true); // Disable save button after successful save
      } else {
        api.updateParent(DBParent.parent_id, accountDetails)
        .then(() => {
          handleSuccess();

        })
        .catch((e) =>{
          console.log(e)
          handleFailure();

        })
        setIsSaveDisabled(true); // Disable save button after successful save

      }
    }
  };

  useEffect(() => {
    if (!DBParent) {
      if (CognitoUser && CognitoUser["cognito:username"]) {
        setDisableInputs(true);
        setAccountDetails((prevState) => ({
          ...prevState,
          username: CognitoUser["cognito:username"],
        }));
      }

      if (CognitoUser && CognitoUser.email) {
        setAccountDetails((prevState) => ({
          ...prevState,
          email: CognitoUser.email,
        }));
      }

      try {
        api.getOneParentUN(CognitoUser["cognito:username"])
        .then((dbParent) => {
          if (dbParent.message == "not_found") {
            setDBParent(null);
          } else {
            setDBParent(dbParent);
            let formatedDate = dayjs(dbParent.dateOfBirth).format("YYYY-MM-DD")
            setAccountDetails({ ...dbParent, isSameAddress: false, dateOfBirth: formatedDate});
          }
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setDisableInputs(true);
      let formatedDate = dayjs(DBParent.dateOfBirth).format("YYYY-MM-DD")
      setAccountDetails({ ...DBParent, isSameAddress: false, dateOfBirth: formatedDate});
    }
  }, []);

  return (
    <div className="pt-5  mx-4">
      <AccSubMenu/>

      <div className="account-container mb-4">
        <h2 className="mb-4">Account Information</h2>
        <div className="input-group">
          <label>Email:*</label>
          <input
            type="email"
            name="email"
            value={accountDetails.email}
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={disableInputs}
          />
          {validationErrors.email && (
            <span className="error">{validationErrors.email}</span>
          )}
        </div>
        <div className="input-group">
          <label>Username:*</label>
          <input
            type="text"
            name="username"
            value={accountDetails.username}
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={disableInputs}
          />
          {validationErrors.username && (
            <span className="error">{validationErrors.username}</span>
          )}
        </div>
        <div className="input-group">
          <label>First Name:*</label>
          <input
            type="text"
            name="firstName"
            value={accountDetails.firstName}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.firstName && (
            <span className="error">{validationErrors.firstName}</span>
          )}
        </div>
        <div className="input-group">
          <label>Last Name:*</label>
          <input
            type="text"
            name="lastName"
            value={accountDetails.lastName}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.lastName && (
            <span className="error">{validationErrors.lastName}</span>
          )}
        </div>
        <div className="input-group">
          <label>Date of Birth:*</label>
          <input
            type="date"
            name="dateOfBirth"
            value={accountDetails.dateOfBirth}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.dateOfBirth && (
            <span className="error">{validationErrors.dateOfBirth}</span>
          )}
        </div>
      </div>

      <div className="account-container mb-4">
        <h2 className="mb-4">Address</h2>
        <div className="input-group">
          <label>Street Address:*</label>
          <input
            type="text"
            name="streetAddressLine1"
            value={accountDetails.streetAddressLine1}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.streetAddressLine1 && (
            <span className="error">{validationErrors.streetAddressLine1}</span>
          )}
        </div>
        <div className="input-group">
          <label>City:*</label>
          <input
            type="text"
            name="city"
            value={accountDetails.city}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.city && (
            <span className="error">{validationErrors.city}</span>
          )}
        </div>
        <div className="input-group">
          <label>State:*</label>
          <input
            type="text"
            name="state"
            value={accountDetails.state}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.state && (
            <span className="error">{validationErrors.state}</span>
          )}
        </div>
        <div className="input-group">
          <label>Zipcode:*</label>
          <input
            type="text"
            name="zipcode"
            value={accountDetails.zipcode}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.zipcode && (
            <span className="error">{validationErrors.zipcode}</span>
          )}
        </div>
        <div className="input-group">
          <label>Country Residence:*</label>
          <input
            type="text"
            name="countryResidence"
            value={accountDetails.countryResidence}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.countryResidence && (
            <span className="error">{validationErrors.countryResidence}</span>
          )}
        </div>
        <div className="input-group mb-5 mt-4">
          <label className="checkboxLabel">
            <input
              type="checkbox"
              name="isSameAddress"
              checked={accountDetails.isSameAddress}
              onChange={handleInputChange}
            />
            Mailing Address same as Street Address
          </label>
        </div>

        {!accountDetails.isSameAddress && (
          <div>
            <h2>Mailing: </h2>
            <div className="input-group">
              <label>Street Address:*</label>
              <input
                type="text"
                name="mailing_streetAddressLine1"
                value={accountDetails.mailing_streetAddressLine1}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {validationErrors.mailing_streetAddressLine1 && (
                <span className="error">
                  {validationErrors.mailing_streetAddressLine1}
                </span>
              )}
            </div>
            <div className="input-group">
              <label>City:*</label>
              <input
                type="text"
                name="mailing_city"
                value={accountDetails.mailing_city}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {validationErrors.mailing_city && (
                <span className="error">{validationErrors.mailing_city}</span>
              )}
            </div>
            <div className="input-group">
              <label>State:*</label>
              <input
                type="text"
                name="mailing_state"
                value={accountDetails.mailing_state}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {validationErrors.mailing_state && (
                <span className="error">{validationErrors.mailing_state}</span>
              )}
            </div>
            <div className="input-group">
              <label>Zipcode:*</label>
              <input
                type="text"
                name="mailing_zipcode"
                value={accountDetails.mailing_zipcode}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {validationErrors.mailing_zipcode && (
                <span className="error">
                  {validationErrors.mailing_zipcode}
                </span>
              )}
            </div>{" "}
          </div>
        )}
      </div>

      <div className="account-container mb-4">
        <h2 className="mb-4">Other</h2>

        <div className="input-group">
          <label>Cell Phone:*</label>
          <input
            type="tel"
            name="cellPhone"
            value={accountDetails.cellPhone}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.cellPhone && (
            <span className="error">{validationErrors.cellPhone}</span>
          )}
        </div>
        <div className="input-group">
          <label>Home Phone:*</label>
          <input
            type="tel"
            name="homePhone"
            value={accountDetails.homePhone}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.homePhone && (
            <span className="error">{validationErrors.homePhone}</span>
          )}
        </div>
        <div className="input-group">
          <label>Work Status:*</label>
          <select
            name="workStatus"
            value={accountDetails.workStatus}
            onChange={handleInputChange}
            onBlur={handleBlur}
          >
            <option value={0}>Select Option:</option>
            <option value={1}>Employed</option>
            <option value={2}>Unemployed</option>
            <option value={3}>Student</option>
            <option value={4}>Retired</option>
            <option value={5}>Self-employed</option>
          </select>
          {validationErrors.workStatus && (
            <span className="error">{validationErrors.workStatus}</span>
          )}
        </div>

        <div className="input-group">
          <label>Race:*</label>
          <select
            name="race"
            value={accountDetails.race}
            onChange={handleInputChange}
            onBlur={handleBlur}
          >
            <option value={0}>Select Option:</option>
            <option value={1}>White</option>
            <option value={2}>Black or African American</option>
            <option value={3}>Asian</option>
            <option value={4}>Native American or Alaska Native</option>
            <option value={5}>Native Hawaiian or Other Pacific Islander</option>
            <option value={6}>Other</option>
          </select>
          {validationErrors.race && (
            <span className="error">{validationErrors.race}</span>
          )}
        </div>

        <div className="input-group">
          <label>Ethnicity:*</label>
          <select
            name="ethnicity"
            value={accountDetails.ethnicity}
            onChange={handleInputChange}
            onBlur={handleBlur}
          >
            <option value={0}>Select Option:</option>
            <option value={1}>Hispanic or Latino</option>
            <option value={2}>Not Hispanic or Latino</option>
            <option value={3}>Prefer not to say</option>
            <option value={4}>Other</option>
          </select>
          {validationErrors.ethnicity && (
            <span className="error">{validationErrors.ethnicity}</span>
          )}
        </div>
      </div>

      <div className="account-container mb-4">
        <h2 className="mb-4">Emergency Contact 1</h2>

        <div className="input-group">
          <label>First Name:*</label>
          <input
            type="text"
            name="emergencyContact1_firstName"
            value={accountDetails.emergencyContact1_firstName}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.emergencyContact1_firstName && (
            <span className="error">
              {validationErrors.emergencyContact1_firstName}
            </span>
          )}
        </div>

        <div className="input-group">
          <label>Last Name:*</label>
          <input
            type="text"
            name="emergencyContact1_lastName"
            value={accountDetails.emergencyContact1_lastName}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.emergencyContact1_lastName && (
            <span className="error">
              {validationErrors.emergencyContact1_lastName}
            </span>
          )}
        </div>

        <div className="input-group">
          <label>Phone:*</label>
          <input
            type="tel"
            name="emergencyContact1_phone"
            value={accountDetails.emergencyContact1_phone}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.emergencyContact1_phone && (
            <span className="error">
              {validationErrors.emergencyContact1_phone}
            </span>
          )}
        </div>
      </div>

      <div className="account-container mb-4">
        <h2 className="mb-4">Emergency Contact 2</h2>

        <div className="input-group">
          <label>First Name:*</label>
          <input
            type="text"
            name="emergencyContact2_firstName"
            value={accountDetails.emergencyContact2_firstName}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.emergencyContact2_firstName && (
            <span className="error">
              {validationErrors.emergencyContact2_firstName}
            </span>
          )}
        </div>

        <div className="input-group">
          <label>Last Name:*</label>
          <input
            type="text"
            name="emergencyContact2_lastName"
            value={accountDetails.emergencyContact2_lastName}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.emergencyContact2_lastName && (
            <span className="error">
              {validationErrors.emergencyContact2_lastName}
            </span>
          )}
        </div>

        <div className="input-group">
          <label>Phone:*</label>
          <input
            type="tel"
            name="emergencyContact2_phone"
            value={accountDetails.emergencyContact2_phone}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {validationErrors.emergencyContact2_phone && (
            <span className="error">
              {validationErrors.emergencyContact2_phone}
            </span>
          )}
        </div>
        </div>
        

        <div className="account-container mb-4">

        <button
          className="btn btn-primary w-100"
          onClick={handleSave}
          disabled={isSaveDisabled}
        >
          Save
        </button>

        <Alert show={showSuccess} variant="success">
        You've successfully added {accountDetails.firstName} {accountDetails.lastName}.
        </Alert>
        <Alert show={showFailure} variant="danger">
        Something went wrong, try again.
        </Alert>
      </div>
    </div>
  );
}
