import { useEffect, useState } from "react";
import dayjs from "dayjs";
let LLLL = "dddd, MMMM D, YYYY h:mm A";
let image = require("../../assets/fall-fest-flier.png");

export default function Feed({ api }) {
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.listAllPosts().then((res) => {
      setPosts(res);
      setLoading(false);

    });
  }, []);
  return (
    <div className="d-flex justify-content-center">
      {!loading && posts.length > 0 ? (
        <div className="feedItem d-flex flex-wrap justify-content-center">
          {posts
            .sort((a, b) => {
              const dateA = new Date(a.dateAndTime);
              const dateB = new Date(b.dateAndTime);
              return dateB - dateA;
            })
            .map((post) => (
              <div key={post.id} className="post bg-light mx-2 mb-5 border w-100">
                <h2 className="p-2">{post.name}</h2>
                <div className="postInfo p-2">

                <img
                  className="p-2 postImage w-100"
                  src={process.env.PUBLIC_URL + "/images/" + post.photoUrl}
                  alt="post"
                ></img>
                <div className="pt-2 px-2">
                  {dayjs(post.dateAndTime).format(LLLL)}
                </div>
                <div className="px-2">{post.description}</div>
                </div>

              </div>
            ))}
        </div>
      ) : (
        <p>No upcoming posts...</p>
      )}
    </div>
  );
}
