import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="p-4 ml-4 mr-4">
      <h3 className="pt-4 d-flex">Follow Us</h3>

      <div className="d-block socialMedia">
        <Link
          target="_blank"
          className="p-2"
          to="https://www.instagram.com/tinymightystrong"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </Link>

        <Link
          target="_blank"
          className="p-2"
          to="https://m.facebook.com/tinymightystrong"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </Link>
      </div>
    </div>
  );
}
