import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
let LLLL = "dddd, MMMM D, YYYY h:mm A";

export default function Events({ api, DBUser, DBParent, DBChild, signinLink }) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [registeredEvents, setRegisteredEvents] = useState([""]);

  useEffect(() => {
    try {
      loading &&
        api.listAllEvents().then((items) => {
          setEvents(items);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (DBParent && DBParent.parent_id) {
      api.getOneAttendee(DBParent.parent_id).then((res) => {
        if(res) {
          let temp = res.map((obj) => obj.event);
          setRegisteredEvents(temp);
        }
      }).catch((e) => {
        console.log(e)
      })


    }
  }, [DBParent]);

  return (
    <div className="d-flex justify-content-center">
      <div className="eventFlex py-5">
        <h1 className="px-4">Upcoming Events</h1>

        {!loading && events.length > 0 ? (
          <div className="d-flex flex-wrap justify-content-center">
            {events
              .sort((a, b) => {
                const dateA = new Date(a.dateAndTime);
                const dateB = new Date(b.dateAndTime);
                return dateA - dateB;
              })
              .filter((event) => event.type === "Event")
              .filter((event) => dayjs(event.dateAndTime).isAfter(dayjs()))
              .map((event) => (
                <div key={event.id} className="event bg-light mx-2 mb-5 border">
                  <h2 className="p-2">{event.name}</h2>
                  <div className="eventInfo p-2">
                    <img
                      className="p-2 eventImage"
                      src={process.env.PUBLIC_URL + "/images/" + event.photoUrl}
                      alt={event.photoUrl}
                    ></img>
                    <div className="pt-2 px-2">
                      {dayjs(event.dateAndTime).format(LLLL)}
                    </div>
                    <div className="px-2">{event.description}</div>

                    {/* commented out since we arent currently doing registration */}
                    {/* {registeredEvents &&
                      !registeredEvents.includes(event.id) && (
                        <>
                          {!DBUser && (
                            <Link className="btn btn-primary" to={signinLink}>
                              Login to Register
                            </Link>
                          )}
                          {DBUser && !DBParent && (
                            <Link
                              className="btn btn-primary"
                              to={`/account/parent`}
                            >
                              Fill out Parent Info to Register
                            </Link>
                          )}
                          {DBUser && DBParent && !DBChild && (
                            <Link
                              className="btn btn-primary"
                              to={`/account/child`}
                            >
                              Fill out Child Info to Register
                            </Link>
                          )}
                          {DBChild && (
                            <Link
                              className="btn btn-primary"
                              to={`/event-signup/${event.id}`}
                            >
                              Sign Up
                            </Link>
                          )}
                        </>
                      )}
                    {registeredEvents &&
                      registeredEvents.includes(event.id) && (
                        <Link className="btn btn-primary disabled">
                          Registered
                        </Link>
                      )} */}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <p>No upcoming events...</p>
        )}
      </div>
    </div>
  );
}
