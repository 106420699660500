import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

export default function EventSignup({ api, DBChild, DBParent }) {
  const eventId = useParams().eventId;

  const [event, setEvent] = useState();

  const [eventDetails, setEventDetails] = useState({
    event: eventId,
    child: DBChild ? DBChild.child_id : null,
    parentSignedUp: DBParent ? DBParent.parent_id : null,
    paid: true,
    formFilled: true,

    // default?
    responseAttendance: true,
    notifyEmergency: true,
    medicalExpenses: true,
    photoRelease: true,
    damageAndRefund: true,
    staffExpectation: true,
    codeOfConduct: true,
    howDidHeard: 0,
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [disableInputs, setDisableInputs] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const handleSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 8000);
  };

  const handleFailure = () => {
    setShowFailure(true);
    setTimeout(() => setShowFailure(false), 8000);
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    setEventDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear validation error once user starts typing in the field
    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    validateInput(name);
  };

  const validateInput = (name) => {
    let error = "";
    const value = eventDetails[name];

    if (!value) {
      error = "This field is required";
    } else if (name === "howDidHeard" && value === 0) {
      error = "This field is required";
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  useEffect(() => {
    setIsSaveDisabled(hasErrors());
  }, [validationErrors]);

  const hasErrors = () => {
    return Object.values(validationErrors).some((error) => error);
  };

  const handleSave = () => {
    if (!hasErrors()) {
      api
        .createAttendee(eventDetails)
        .then((res) => {
          handleSuccess()
          setIsSaveDisabled(true)
        })
        .catch((e) => {
          handleFailure()
          console.log(e);
        });

    }
  };

  useEffect(() => {
    api
      .getOneEvent(eventId)
      .then((event) => {
        setEvent(event);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    setEventDetails({
      ...eventDetails,
      child: DBChild ? DBChild.child_id : null,
      parentSignedUp: DBParent ? DBParent.parent_id : null,
    });
  }, [DBChild]);


  return (
    <div className="pt-5  mx-4">
      <div className="event-container mb-4">
        {event && <h2 className="mb-4">Event Signup for "{event.name}"</h2>}
        {!event && <h2 className="mb-4">Event Signup for " "</h2>}

        <div className="input-group">
          <label>How did you hear about us?:*</label>
          <select
            name="howDidHeard"
            value={eventDetails.howDidHeard}
            onChange={handleInputChange}
            onBlur={handleBlur}
          >
            <option value={0}>Select Option:</option>
            <option value={1}>Friend or Family</option>
            <option value={2}>Social Media</option>
            <option value={3}>Internet Search</option>
            <option value={4}>School or Educational Institution</option>
            <option value={5}>Advertisement</option>
            <option value={6}>Event or Conference</option>
            <option value={7}>Word of Mouth</option>
            <option value={8}>Other Organization</option>
            <option value={9}>Previous Participant</option>
            <option value={10}>Other</option>
          </select>
          {validationErrors.howDidHeard && (
            <span className="error">{validationErrors.howDidHeard}</span>
          )}
        </div>
      </div>

      <div className="event-container mb-4">
        <button
          className="btn btn-primary w-100"
          onClick={handleSave}
          disabled={isSaveDisabled}
        >
          Register
        </button>

        {DBChild && event && <Alert show={showSuccess} variant="success">
          You've successfully registered {DBChild.firstName} {DBChild.lastName} for {event.name}
        </Alert>}
        <Alert show={showFailure} variant="danger">
          Something went wrong, try again.
        </Alert>
      </div>
    </div>
  );
}
