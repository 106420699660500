import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

let irene = require("../../assets/staffPictures/Irene.jpg");




export default function PrivacyPolicy() {

return(

    <div>
        <div className="threeTextOneImgContainer">
            <div>
                <h4><em>#TeamTMS</em></h4>
                <h1>Meet Irene</h1>
                <p> A native of the Klamath Basin, Irene graduated in 2023 with her Bachelor’s of Science in Applied Psychology. Her studies have allowed her to gain a better understanding of humans, and she has been more compelled to work with others and help them in any way possible. She has been involved in youth work for many years and strives to continue to empower them, as she believes they have the power to change the world. <br /><br />

In her downtime, Irene enjoys reading (a lot!), and going on walks. Sunsets are her favorite because their patterns are never the same, yet they are always beautiful. 
</p>
                <br />
                <h4><em>Contact Info</em></h4>
                <p><FontAwesomeIcon icon={faEnvelope}/> <b> Email - iaguirre@tinymightystrong.org</b></p>
            </div>
            <div>
                <img src={irene} alt="" />
            </div>
        </div>


    </div>


)
}