import WOW from "wowjs";
import { useEffect, useState, useRef } from "react";
import "../../../node_modules/wowjs/css/libs/animate.css";



export default function Stats({
  youthServed,
  retentionRate,
  communitiesServed,
  yearsOfService,
}) {
  const [youthCount, setYouthCount] = useState(7020);
  const [retentionCount, setRetentionCount] = useState(0);
  const [communityCount, setCommunityCount] = useState(0);
  const [yearCount, setYearCount] = useState(0);

  const countRef = useRef(null);

  useEffect(() => {
    const wow = new WOW.WOW({
      callback: (box) => {
        if (box.classList.contains("count-up")) {
          startCountUp();
        }
      },
    });

    wow.init();

    const startCountUp = () => {
      createInterval(100, youthServed, setYouthCount, 1);
      createInterval(1, retentionRate, setRetentionCount, 16);
      createInterval(1, communitiesServed, setCommunityCount, 325);
      createInterval(1, yearsOfService, setYearCount, 650);
    };

    function createInterval(step, limit, stateFunct, interval) {
      const intervalId = setInterval(() => {
        stateFunct((prevCount) => {
          const newCount = prevCount + step;
          if (newCount >= limit) {
            clearInterval(intervalId);
            stateFunct(limit);
            return limit;
          }
          return newCount;
        });
      }, interval);
    }
  }, [communitiesServed, retentionRate, yearsOfService, youthServed]);

  return (
    <div className="bg-dark w-100 stats yeseva-one-regular mb-5">
      <div
        ref={countRef}
        className="h-100 wow fadeIn count-up gridTemp align-items-center justify-content-center p-5"
        data-wow-duration="1s"
      >


        <div>
          <h1 className="text-primary px-5">{youthCount}</h1>
          <h6 className="text-white px-5 h6">Youth Served Since 2019</h6>
        </div>

        <h1 className="text-white dash1">/</h1>

        <div>
        <h1 className="text-primary px-5">{retentionCount}</h1>
        <h6 className="text-white px-5 h6">Percent Retention Rate</h6>
        </div>

        <h1 className="text-white dash2">/</h1>


        <div>
        <h1 className="text-warning px-5">{communityCount}</h1>
        <h6 className="text-white px-5 h6">Communities Served</h6>
        </div>


        <h1 className="text-white dash3">/</h1>


        <div>
        <h1 className="text-primary px-5">{yearCount}</h1>
        <h6 className="text-white px-5 h6">Years of Service</h6>
        </div>
      </div>
    </div>
  );
}